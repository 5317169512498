@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* height: 100vh !important; */
  /* overflow: hidden; */
  -webkit-print-color-adjust: exact !important;
  background-color: aliceblue;
  padding: 0;
  margin: 0;
}

@page {
  size: 595px 842px;
  margin: 0;
  padding: 0;
}

@media print {
  #preview-wrapper {
    overflow-y: unset;
    padding: 0;
    display: initial;
  }
}

.ql-toolbar.ql-snow {
  border: 2px solid #eee;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ql-container.ql-snow {
  border: 2px solid #eee;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#preview-page a:hover {
  color: inherit;
}
